import { Avatar, Box, Flex, Image, Spacer, Menu, MenuList, MenuButton, MenuItem, Divider } from "@chakra-ui/react";
import { ROUTES } from "../Routes";
import { Link, SignOutLink } from "../components";
import logo from "../images/brand-logo.png";
import { StickyHeader } from "./StickyHeader";
import { ApplicationSideBar } from "./ApplicationSideBar";
import { ShortcutsWidget } from "./ShortcutsWidget";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, generatePath } from "react-router-dom";
import { useAuthenticatedUser } from "../hooks";

export const ApplicationHeader = () => {
    const { user } = useAuthenticator((context) => [context.user]);
    const { isAdminRole } = useAuthenticatedUser();
    const navigate = useNavigate();

    return (
        <StickyHeader>
            <Flex alignItems="center" width="100%" height="100%">
                <Spacer display={{ md: "none" }}>
                    <ApplicationSideBar />
                </Spacer>

                <Box position={{ base: "relative", md: "fixed" }}>
                    <Link to={ROUTES.HOME}>
                        <Image src={logo} />
                    </Link>
                </Box>

                <Spacer />
                <ShortcutsWidget />

                <Box display={{ base: "none", md: "inline" }} mr={25}>
                    <Menu>
                        <MenuButton as="button" rounded={"full"} cursor={"pointer"}>
                            <Avatar size={"sm"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem cursor={"unset"} textColor={"gray.400"}>
                                {user.username}
                            </MenuItem>
                            {!isAdminRole ? (
                                <>
                                    <MenuItem onClick={() => navigate(generatePath(ROUTES.LIST_USERS))}>
                                        Manage Users
                                    </MenuItem>
                                    <Divider />
                                </>
                            ) : null}
                            <MenuItem>
                                <SignOutLink />
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Box>
            </Flex>
        </StickyHeader>
    );
};
