import { useParams } from "react-router-dom";
import {
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
    PopoverCloseButton,
} from "@chakra-ui/react";
import { CopyButton, IconButtonInfo, IconButtonExternalLink } from "../components";
import { useStore } from "../hooks";

const ShortcutLink = ({
    shortcut,
    url,
    info,
    param,
    disabled = false,
}: {
    shortcut: string;
    url: string;
    info: string;
    param: string;
    disabled?: boolean;
}) => {
    return (
        <li style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "8px" }}>
            <strong>{shortcut}</strong>
            <span>
                <Popover>
                    <PopoverTrigger>
                        <IconButtonInfo iconSize="32px" />
                    </PopoverTrigger>
                    <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>{info}</PopoverBody>
                    </PopoverContent>
                </Popover>
                <IconButtonExternalLink
                    isDisabled={disabled === true || url === ""}
                    onClick={() => window.open(`https://${url}${param}`, "_blank")}
                    iconSize="32px"
                />
                <CopyButton value={param} fontSize="24px" disabled={disabled} />
            </span>
        </li>
    );
};

export const ShortcutsWidget = () => {
    const params = useParams<Record<string, string>>();
    const url = params?.customerUrl ?? "";
    const { data: store } = useStore({ config: { enabled: url !== "" }, domain: url });
    const disablePreviewMode = url === "" || store?.mode !== "testing";

    return (
        <Popover>
            <PopoverTrigger>
                <Button mr={25}>Test Shortcuts</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                    <div style={{ padding: "20px 10px 10px" }}>
                        <p style={{ textAlign: "center" }}>
                            Add any of these to the end of your URL to force a Nostra event
                        </p>
                        <ul style={{ listStyle: "none" }}>
                            <ShortcutLink
                                shortcut="?skipCache"
                                url={url}
                                info={
                                    "Stops Nostra from running on page. Use this to test if the Nostra cached page is not up to date"
                                }
                                param="?skipCache"
                            />
                            <ShortcutLink
                                shortcut="?forceEdge"
                                url={url}
                                info={"Requires Nostra to run. Use when you're running a split test to review Nostra."}
                                param="?forceEdge"
                            />
                            <ShortcutLink
                                shortcut="?forceSeo"
                                url={url}
                                info={
                                    "Displays how bots see a page. Use when you want to confirm crawlers will see SEO changes."
                                }
                                param="?forceSeo"
                            />
                            <ShortcutLink
                                shortcut="?debug"
                                url={url}
                                info={
                                    "Display how Nostra is set up for a page. Use to identify your current test group."
                                }
                                param="?debug"
                            />
                            <ShortcutLink
                                shortcut="Preview Mode"
                                url={url}
                                info={"See how Nostra will run on your website before enabling for customers"}
                                param="?developmentPreview=808&forceEdge"
                                disabled={disablePreviewMode}
                            />
                        </ul>
                    </div>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};
