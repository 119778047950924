import { useSearchParams } from "react-router-dom";
import { useRevenueReport } from "../../../../hooks";
import { IntervalForm } from "./MetricParameters";
import { CSVAndSQL } from "../CSVAndSQL";
import { RenderMetric } from "../RenderMetric";

export const OrderTaggingCoverageByInterval = () => {
    const [searchParams] = useSearchParams();
    const report = "order_coverage";
    const prefix = `${report}_by_interval`;

    const show_text =
        searchParams.get(`${prefix}_show_text`) === null || searchParams.get(`${prefix}_show_text`) === "true"
            ? true
            : false;

    const metricParams = {
        interval_unit: searchParams.get(`${prefix}_interval_unit`) ?? "days",
        interval_count: searchParams.get(`${prefix}_interval_count`) ?? 1,
        show_text,
    };

    const { data, isLoading, isError } = useRevenueReport({ report, ...metricParams });

    return (
        <RenderMetric data={data} isError={isError} isLoading={isLoading}>
            <CSVAndSQL csv={data?.csv} sql={data?.query} />
            <IntervalForm prefix={prefix} params={metricParams} />
        </RenderMetric>
    );
};
