import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { Select, Button, Flex } from "@chakra-ui/react";
import { FieldError, FieldSet, Label } from "../../../../components";

const dateRangeOptions = [
    {
        label: "Last 7 days",
        value: "7",
    },
    {
        label: "Last 30 days",
        value: "30",
    },
    {
        label: "Last 90 days",
        value: "90",
    },
];

type ReportParametersProps = {
    setDateRange: (dateRange: { from_hour: string; upto_hour: string }) => void;
};

export const ReportParameters = ({ setDateRange }: ReportParametersProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            dateRange: "7",
        },
    });

    const handleReportSubmit = (data: any) => {
        const dateRange = {
            from_hour: dayjs().subtract(data.dateRange, "day").startOf("day").format("YYYY-MM-DD"),
            upto_hour: dayjs().endOf("day").format("YYYY-MM-DD"),
        };
        setDateRange(dateRange);
    };

    return (
        <form onSubmit={handleSubmit(handleReportSubmit)}>
            <FieldSet legend="Parameters" style={{ marginTop: 12 }}>
                {/* Date Range */}
                <Label minWidth={"80px"} style={{ display: "inline-flex" }}>
                    Date Range
                </Label>
                <Select
                    {...register("dateRange", { required: true })}
                    isRequired={true}
                    maxWidth="fit-content"
                    display={"inline-flex"}
                >
                    {dateRangeOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
                <FieldError>{errors.dateRange?.message}</FieldError>
            </FieldSet>

            <Flex pb="spacer-6" justifyContent={"flex-end"}>
                <Button colorScheme={"button-primary"} type="submit">
                    Submit
                </Button>
            </Flex>
        </form>
    );
};
