import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { SVC_URLS } from "../../config/SVC_URLS";
import { fetchOptions } from "../../lib/http/fetchOptions";
import { handleErrors } from "../../lib/http/handleErrors";
import { fetchResponse } from "../../lib/http/fetchResponse";

export const useExperiments = () => {
    const params = useParams<Record<string, string>>(); // @TODO: Use a more specific type to represent the expected route params
    const customerUrl = params.customerUrl!;
    return useQuery(["experiments", customerUrl], () => getExperiments(customerUrl));
};

const getExperiments = async (customerUrl: string) => {
    const GET_EXPERIMENT_SPLIT = `${SVC_URLS["portal"]}/reports/experiments/`;
    const options = await fetchOptions({ headers: { Store: customerUrl } });
    const response = await fetch(GET_EXPERIMENT_SPLIT, options).catch(handleErrors);
    return await fetchResponse(response);
};
