import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconInfoCircle } from "@tabler/icons-react";

export const IconButtonInfo = forwardRef(({ children, iconSize, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"Info"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconInfoCircle stroke={"1.25"} size={iconSize} />}
        >
            {children}
        </IconButton>
    );
});
