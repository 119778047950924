import { forwardRef, IconButton } from "@chakra-ui/react";
import { IconExternalLink } from "@tabler/icons-react";

export const IconButtonExternalLink = forwardRef(({ children, iconSize, ...rest }, ref) => {
    return (
        <IconButton
            ref={ref}
            aria-label={"External Link"}
            {...rest}
            variant="ghost"
            colorScheme="gray"
            cursor="pointer"
            icon={<IconExternalLink stroke={"1.25"} size={iconSize} />}
        >
            {children}
        </IconButton>
    );
});
