import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { useExperiments } from "../../../../hooks";
import { Input, Select, Button, Flex } from "@chakra-ui/react";
import { FieldError, FieldSet, Label } from "../../../../components";
import { percentileOptions, getDatesFromExperiment } from "../../../../lib";

export const ReportParameters = () => {
    const { isLoading, data: experiments } = useExperiments();
    const [searchParams, setSearchParams] = useSearchParams();
    const [minMaxDates, setMinMaxDates] = useState({
        min: "2024-06-01",
        max: dayjs().endOf("day").format("YYYY-MM-DD"),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm({
        defaultValues: {
            from_hour: searchParams.get("dateStart") ?? dayjs().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
            upto_hour: searchParams.get("dateEnd") ?? dayjs().endOf("day").format("YYYY-MM-DD"),
            sale_strategy: searchParams.get("sale_strategy") ?? "subtract_discount_original",
            attribution: searchParams.get("attribution") ?? "visitors",
            percentile: searchParams.get("percentile") ?? "1",
        },
        values: {
            experiment: searchParams.get("experiment") ?? experiments?.[0]?.id,
        },
    });

    const experimentId = watch("experiment");

    useEffect(() => {
        const experiment = experiments?.find((exp: any) => exp.id === experimentId);
        const { min, from_hour, max, upto_hour } = getDatesFromExperiment(experiment?.createdAt, experiment?.endedAt);
        setMinMaxDates({ min: dayjs(min).format("YYYY-MM-DD"), max: dayjs(max).format("YYYY-MM-DD") });
        setValue("from_hour", dayjs(from_hour).format("YYYY-MM-DD"));
        setValue("upto_hour", dayjs(upto_hour).format("YYYY-MM-DD"));
    }, [experimentId, experiments, setValue, setMinMaxDates, minMaxDates.max]);

    const handleReportSubmit = (data: any) => {
        setSearchParams(data, { preventScrollReset: true });
    };

    return (
        <form onSubmit={handleSubmit(handleReportSubmit)}>
            <FieldSet legend="Parameters" style={{ marginTop: 12 }}>
                {/* Experiment Id */}
                {!isLoading && experiments?.length > 0 && (
                    <>
                        <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                            Experiment Id
                        </Label>
                        <Select {...register("experiment")} maxWidth="fit-content" display={"inline-flex"}>
                            {experiments.map((option: any) => (
                                <option value={option.id} key={option.id}>
                                    {option.id}
                                </option>
                            ))}
                        </Select>
                        <FieldError>{errors.experiment?.message?.toString()}</FieldError>
                    </>
                )}

                {/* Date Range */}
                <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                    From
                </Label>
                <Input
                    type="date"
                    {...register("from_hour", { required: true })}
                    maxWidth={150}
                    min={minMaxDates.min}
                    max={minMaxDates.max}
                />
                <FieldError>{errors.from_hour?.message}</FieldError>

                <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                    To
                </Label>
                <Input
                    type="date"
                    {...register("upto_hour", { required: true })}
                    maxWidth={150}
                    min={minMaxDates.min}
                    max={minMaxDates.max}
                />
                <FieldError>{errors.upto_hour?.message}</FieldError>

                {/* Percentile */}
                <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                    Percentile
                </Label>
                <Select
                    {...register("percentile", { required: true })}
                    isRequired={true}
                    maxWidth="fit-content"
                    display={"inline-flex"}
                >
                    {percentileOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </Select>
                <FieldError>{errors.percentile?.message}</FieldError>

                {/* Sale Strategy */}
                <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                    Strategy
                </Label>
                <Select
                    {...register("sale_strategy", { required: true })}
                    maxW={{ base: "185px", sm: "fit-content" }}
                    display={"inline-flex"}
                >
                    <option value="original">Original</option>
                    <option value="subtract_discount_original">Subtract Discount Original</option>
                </Select>
                <FieldError>{errors.sale_strategy?.message}</FieldError>

                {/* Attribution */}
                <Label minWidth={"106px"} style={{ display: "inline-flex" }}>
                    Attribution
                </Label>
                <Select
                    {...register("attribution", { required: true })}
                    maxW={{ base: "185px", sm: "fit-content" }}
                    display={"inline-flex"}
                >
                    <option value="visitors">Visitors</option>
                    <option value="sessions">Sessions</option>
                </Select>
                <FieldError>{errors.attribution?.message}</FieldError>
            </FieldSet>

            <Flex pb="spacer-6" justifyContent={"flex-end"}>
                <Button colorScheme={"button-primary"} type="submit">
                    Submit
                </Button>
            </Flex>
        </form>
    );
};
