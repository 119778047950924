import dayjs from "dayjs";

export const getDatesFromExperiment = (startedAt: string, endedAt: string | null) => {
    let from_hour = startedAt;
    let upto_hour = endedAt;
    let min = "2024-06-01"; // This date is the beginning of our data collection
    let max = endedAt;

    // if the upto_hour is null
    // - then the experiment is still running
    // - and max should be today
    // - and upto_hour should be today
    if (upto_hour === null) {
        max = dayjs().endOf("day").format("YYYY-MM-DD");
        upto_hour = dayjs().endOf("day").format("YYYY-MM-DD");
    }

    // if the experiment from_hour is before we started colleding data
    // - then we should use the start of data collection as the from_hour
    // - otherwise we should set min to the start of the experiment
    if (dayjs(from_hour).isBefore(min)) {
        from_hour = min;
    } else {
        min = from_hour;
    }

    return {
        min, // The earliest selectable date
        from_hour, // The selected start date
        upto_hour, // The selected end date
        max, // The latest selectable date
    };
};
